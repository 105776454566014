export class LicenseInfo {
    title?: string = ""
    artist: string = ""
    license?: string = undefined
    licenseShortName?: string = ""
    usageTerms?: string = ""
    attributionRequired?: boolean = false
    copyrighted?: boolean = false
    credit?: string = ""
    description?: string = ""
    informationLocation?: URL = undefined
    date?: Date
    views?: number
}
